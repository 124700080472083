@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 48.8 99.2% 51.2%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --destructive: 348.7 74.4% 47.5%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 20 14.3% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 60 9.1% 97.8%;
    --card: 20 14.3% 4.1%;
    --card-foreground: 60 9.1% 97.8%;
    --popover: 20 14.3% 4.1%;
    --popover-foreground: 60 9.1% 97.8%;
    --primary: 47.9 95.8% 53.1%;
    --primary-foreground: 26 83.3% 14.1%;
    --secondary: 12 6.5% 15.1%;
    --secondary-foreground: 60 9.1% 97.8%;
    --muted: 12 6.5% 15.1%;
    --muted-foreground: 24 5.4% 63.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 60 9.1% 97.8%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 12 6.5% 15.1%;
    --input: 12 6.5% 15.1%;
    --ring: 35.5 91.7% 32.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  html,
  body {
    /* @apply font-sans; */
    font-family: var(--font-montserrat), sans-serif !important;
  }

  h1 {
    @apply text-2xl font-bold leading-tight text-gray-900;
    @apply sm:text-3xl md:text-4xl;
  }

  h2 {
    @apply text-xl font-semibold leading-snug text-gray-800;
    @apply sm:text-2xl md:text-3xl;
  }

  h3 {
    @apply text-lg font-medium leading-snug text-gray-800;
    @apply sm:text-xl md:text-2xl;
  }

  h4 {
    @apply text-base font-medium leading-relaxed text-gray-700;
    @apply sm:text-lg md:text-xl;
  }

  h5 {
    @apply text-sm font-medium leading-relaxed text-gray-700;
    @apply sm:text-base md:text-lg;
  }

  h6 {
    @apply text-xs font-medium leading-relaxed text-gray-600;
    @apply sm:text-sm md:text-base;
  }

  p {
    @apply mb-3 text-gray-700 leading-relaxed;
  }

  blockquote {
    @apply pl-4 border-l-4 border-gray-300 italic text-gray-600 mt-4 mb-4;
  }

  /* CMS styles - text */

  .cms-content span {
    font-family: var(--font-montserrat), sans-serif !important;
  }
  .cms-content p {
    font-family: var(--font-montserrat), sans-serif !important;
  }
  /* Lists */
  .cms-content ul {
    @apply list-disc pl-5 mb-4;
  }
  .cms-content ol {
    @apply list-decimal pl-5 mb-4;
  }
  .cms-content li {
    @apply mb-1 text-gray-700;
  }

  /* Links */
  .cms-content a {
    @apply text-blue-600 underline hover:text-blue-800;
  }

  /* Images */
  .cms-content img {
    @apply max-w-full h-auto rounded-lg shadow-sm;
  }

  /* Dividers */
  .cms-content hr {
    @apply my-4 border-t border-gray-300;
  }

  /* Bold and Strong Text */
  .cms-content strong {
    @apply font-semibold;
  }

  /* Custom Spacing for Nested Elements */
  .cms-content > *:not(:last-child) {
    @apply mb-4;
  }
}

.hide-number-stepper {
  /* For most browsers */
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  @apply [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker__input-container {
  @apply w-full rounded-md border p-2;
}

.react-datepicker-wrapper input {
  @apply w-full focus:outline-none focus:ring-2 focus:ring-primary;
}

.other-amount-input {
  @apply bg-gray-100 border-0 text-center;
}

.tab-trigger {
  @apply p-1.5 border-b-2 rounded-none border-transparent data-[state=active]:border-red-600 transition-colors;
}

.tab-trigger[data-state='active'] {
  @apply border-red-600;
}

.donation-box {
  @apply bg-gray-100 p-3 md:p-4;
}

.link {
  @apply text-blue-500 underline;
}
